import React, { useRef, useState } from "react";
import { env_intercom_app_id } from "../config/config";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { color, font } from "./templates/ui";
import { IntercomProvider } from "react-use-intercom";
import "babel-polyfill";
import { AppTheme } from "./contexts/AppTheme";
import PrivateRoute from "./organisms/PrivateRoute";
import { FlashMessageProvider } from "./contexts/FlashMessage";
import { Helmet } from "react-helmet";
import { getAppTheme, getAppThemeName } from "../services/appTheme";
import { TutorialContext } from "./contexts/Tutorial";
import smoothscroll from "smoothscroll-polyfill";
import { ErrorBoundary } from "../services/bugsnag";
import OfflineMessage from "./organisms/OfflineMessage";
import MaintenanceMode from "./atoms/MaintenanceMode";
import useScrollTopOnPageChange from "../hooks/useScrollTopOnPageChange";
import { StatisticsProvider } from "../hooks/useStatistics";
import { SWRConfig } from "swr";
import Client from "../api/Client";
import { pageRoutes } from "../routes";
import LoadChunkErrorBoundary from "./organisms/LoadChunkErrorBoundary";

smoothscroll.polyfill();

const StyledApp = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${color.paragraph.paragraph};
  font-size: ${font.small};
  font-family: "Isidora Sans Webfont", sans-serif;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media only screen and (min-width: 576px) {
    font-size: ${font.base};
  }

  *,
  & {
    box-sizing: border-box;
    font-family: ${font.family};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  p {
    line-height: ${font.xlarge};
    margin-bottom: 20px;

    @media only screen and (min-width: 576px) {
      line-height: ${font.xlarge};
    }
  }
`;

const StyledWrapper = styled.div`
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ScrollTop = ({ appContainerRef }) => {
  useScrollTopOnPageChange(appContainerRef);

  return null;
};

/*
 * Only render the LoadChunkErrorBoundary in production,
 * in DEV we want to see the error in the console and if
 * we keep the ErrorBoundary we will get into an infinite loop.
 */

class ErrorChunksBoundary extends React.Component {
  render() {
    const ErrorElement = process.env.NODE_ENV === "production" ? LoadChunkErrorBoundary : React.Fragment;
    return <ErrorElement>{this.props.children}</ErrorElement>;
  }
}

const App = () => {
  const appContainerRef = useRef(null);

  const tutorial = useState({
    showAttachmentTutorial: !localStorage.getItem("attachmentTutorialSeen"),
    showQrInvitationTutorial: !localStorage.getItem("qrInvitationTutorialSeen")
  });

  const appTheme = getAppTheme();
  const appThemeName = getAppThemeName();

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <ErrorChunksBoundary>
          <SWRConfig
            value={{
              fetcher: Client.get
            }}
          >
            <IntercomProvider appId={env_intercom_app_id}>
              <ThemeProvider theme={appTheme}>
                <BrowserRouter>
                  <ScrollTop appContainerRef={appContainerRef} />
                  <AppTheme.Provider value={appThemeName}>
                    <Helmet>
                      <title>{appTheme.name}</title>
                      <link rel="shortcut icon" href={`/img/${appThemeName}-appicon.png`} />
                      <link rel="apple-touch-icon" sizes="120x120" href={`/img/${appThemeName}-apple-touch-icon.png`} />
                      <link rel="icon" type="image/png" sizes="32x32" href={`/img/${appThemeName}-appicon.png`} />
                    </Helmet>
                    <FlashMessageProvider>
                      <StyledApp className="App" ref={appContainerRef} data-testid="app">
                        <OfflineMessage />
                        <MaintenanceMode />
                        <TutorialContext.Provider value={tutorial}>
                          <StatisticsProvider>
                            <StyledWrapper>
                              <Switch>
                                {Object.values(pageRoutes).map(pageRoute => {
                                  const RouteComponent = pageRoute.private ? PrivateRoute : Route;

                                  return (
                                    <RouteComponent
                                      key={pageRoute.path}
                                      path={pageRoute.path}
                                      exact={pageRoute.exact}
                                      component={pageRoute.component}
                                      disableReturnAfterUnauthenticated={pageRoute.disableReturnAfterUnauthenticated}
                                      hasPermissionToViewPage={pageRoute.hasPermissionToViewPage}
                                    />
                                  );
                                })}
                              </Switch>
                            </StyledWrapper>
                          </StatisticsProvider>
                        </TutorialContext.Provider>
                      </StyledApp>
                    </FlashMessageProvider>
                  </AppTheme.Provider>
                </BrowserRouter>
              </ThemeProvider>
            </IntercomProvider>
          </SWRConfig>
        </ErrorChunksBoundary>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default App;

export const api_endpoint = "https://sabia-api.payrollselect.nl";

export const bugsnag_key = "89da413eec226813988f47a2b0231e6b";
export const min_start_date = new Date("01-01-2020");
export const release_stage = "production";
export const intercom_app_id = "dzmw9ffi";
export const google_analytics_id = "UA-192875991-1";
export const easyPayrollBaseUrl = "https://www.easypayroll.nl/";
export const easyPayrollApiUrl = "https://www.easypayroll.nl/wp-json/dij";
export const featureSearchAddressBarEnabled = false;
export const picNicCompanyId = "808c7fa6-8b67-4655-bb84-2ac5bc04efde";
export const maintenance_window_end = 1673262000;

export const appThemes = {
  "picnic.payrollselect.nl": "picnic",
  "app.payrollselect.nl": "payroll",
  "app.easyworx.nl": "easyworx",
  "registratie.easyjobs.nl": "easyjobs",
  "app.easypayroll.nl": "easypayroll",
  "app.flexplein.nl": "flexplein",
  "app.rpostaffing.nl": "rpo",
  "gorillas.payrollselect.nl": "gorillas"
};
